import React from 'react';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ABBImage from "../assets/ABB.png";
import ALAsabImage from "../assets/AlAsab.jpg";
import MottImage from "../assets/Mott.png";
import RambollImage from "../assets/Ramboll.png";
import SiemensImage from "../assets/Siemens.png";
import { makeStyles } from '@material-ui/core/styles';
import Background from "../assets/backgroundsea.png";
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ReactFontLoader from 'react-font-loader';
import { useState } from 'react';
import Loading from "./loading"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../index.css'


import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
    maindiv: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden',
        paddingTop: '6vw',
        paddingRight: '3vw',
        paddingBottom: '6vw'
    },
    root: {
        // background: '#f8f8f8',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'hidden',
        width: '95%',

    },
    h: {
        fontWeight: '1000',
        color: 'white',
        letterSpacing: '0.7vw'
    },

    mainsubdiv: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: '-1.5vw',
        marginLeft: '-2.5vw'
    },
    card: {
        height: '100%',
        width: '280px',
        // maxWidth: '25vw',
        // paddigLeft: '0px', paddingRight:'0px' ,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
        // paddingTop:'3vh',
        background: '#006ad0',

    },
    clientname: {
        color: 'white',
        fontVariant: 'subtitle1'
    },
    clienttext: {
        color: 'white',
        fontVariant: 'subtitle2',
    },
    image: {
        position: 'relative',

    },
    animediv: {
        position: 'absolute',
        top: '0',
        left: '1',
        width: '280px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignContent: 'center',
        // width:
        // marginBottom:'7.5vh', marginTop:'4.5vh',
        alignItems: 'center',
        justifyItems: 'center',
        opacity: 0,
        background: '#0555a4',
        color: 'white'

    }

}))

const movies = [{
        key: 1,
        client: 'ABB',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore veniam magnam repellat explicabo voluptatem facere. Incidunt ex voluptatum esse amet.',
        imag: ABBImage

    },
    {
        key: 2,
        client: 'Mott Macdonald',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore veniam magnam repellat explicabo voluptatem facere. Incidunt ex voluptatum esse amet.',
        imag: MottImage

    },
    {
        key: 3,
        client: 'AL Asab',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore veniam magnam repellat explicabo voluptatem facere. Incidunt ex voluptatum esse amet.',
        imag: ALAsabImage

    },
    {
        key: 4,
        client: 'Siemens',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore veniam magnam repellat explicabo voluptatem facere. Incidunt ex voluptatum esse amet.',
        imag: SiemensImage

    },
    {
        key: 5,
        client: 'Ramball',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore veniam magnam repellat explicabo voluptatem facere. Incidunt ex voluptatum esse amet.',
        imag: RambollImage

    }
]


const handleDragStart = (e) => e.preventDefault();




function ClientCarousel({ clientlist }) {
    const clients = useSelector(state => state.clients)
    const clientsToDisp = clients.filter((client) => client.display === true)

    const matches1 = useMediaQuery('(min-width:1478px)');
    const matches2 = useMediaQuery('(min-width:752px)');


    const classes = useStyles();

    const items = [



        //     <Paper elevation={13}      
        //     className={`${classes.card} card contanim`}

        //   >


        //     <img className={classes.image} src={SiemensImage} height="150vh" width="280px" alt="" />
        //     <div className={`${classes.animediv} anidiv`}>
        //       <Typography style={{fontFamily: 'Rubik'}} className={classes.clienttext} >
        //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, natus nemo. Tempora excepturi adipisci est qui blanditiis impedit nemo! Vitae.
        //       </Typography></div>
        //     <Typography style={{fontFamily: 'Rubik'}} className={classes.clientname} >
        //       Siemens
        //     </Typography>
        //   </Paper>,
        //     <Paper elevation={13}     
        //     className={`${classes.card} card contanim`}

        //   >


        //     <img className={classes.image} src={SiemensImage} height="150vh" width="280px" alt="" />
        //     <div className={`${classes.animediv} anidiv`}>
        //       <Typography style={{fontFamily: 'Rubik'}} className={classes.clienttext} >
        //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, natus nemo. Tempora excepturi adipisci est qui blanditiis impedit nemo! Vitae.
        //       </Typography></div>
        //     <Typography style={{fontFamily: 'Rubik'}} className={classes.clientname} >
        //       Siemens
        //     </Typography>
        //   </Paper>,
        //     <Paper elevation={13}         
        //     className={`${classes.card} card contanim`}

        //   >


        //     <img className={classes.image} src={SiemensImage} height="150vh" width="280px" alt="" />
        //     <div className={`${classes.animediv} anidiv`}>
        //       <Typography style={{fontFamily: 'Rubik'}} className={classes.clienttext} >
        //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, natus nemo. Tempora excepturi adipisci est qui blanditiis impedit nemo! Vitae.
        //       </Typography></div>
        //     <Typography style={{fontFamily: 'Rubik'}} className={classes.clientname} >
        //       Siemens
        //     </Typography>
        //   </Paper>,

        // <Paper elevation={13}

        // className={`${classes.card} card contanim`}

        // >


        // <img className={classes.image} src={SiemensImage} height="150vh" width="280px" alt="" />
        // <div className={`${classes.animediv} anidiv`}>
        //   <Typography style={{fontFamily: 'Rubik'}} className={classes.clienttext} >
        //     Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, natus nemo. Tempora excepturi adipisci est qui blanditiis impedit nemo! Vitae.
        //   </Typography></div>
        // <Typography style={{fontFamily: 'Rubik'}} className={classes.clientname} >
        //   Siemens
        // </Typography>
        // </Paper>,

        // <Paper elevation={13}   
        //                className={`${classes.card} card contanim`}

        //              >
        //                <img className={classes.image} src={SiemensImage} height="150vh" width="280px" alt="" />
        //                <div className={`${classes.animediv} anidiv`}>
        //                  <Typography style={{fontFamily: 'Rubik'}} className={classes.clienttext} >
        //                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, natus nemo. Tempora excepturi adipisci est qui blanditiis impedit nemo! Vitae.
        //                  </Typography></div>
        //                <Typography style={{fontFamily: 'Rubik'}} className={classes.clientname} >
        //                  Siemens
        //                </Typography>
        //              </Paper>,


        // <Paper elevation={13}  
        //                className={`${classes.card} card contanim`}

        //              >


        //                <img className={classes.image} src={SiemensImage} height="150vh" width="280px" alt="" />
        //                <div className={`${classes.animediv} anidiv`}>
        //                  <Typography style={{fontFamily: 'Rubik'}} className={classes.clienttext} >
        //                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, natus nemo. Tempora excepturi adipisci est qui blanditiis impedit nemo! Vitae.
        //                  </Typography></div>
        //                <Typography style={{fontFamily: 'Rubik'}} className={classes.clientname} >
        //                  Siemens
        //                </Typography>
        //              </Paper>,



        // <Paper elevation={13}


        //                className={`${classes.card} card contanim`}

        //              >


        //                <img className={classes.image} src={SiemensImage} height="150vh" width="280px" alt="" />
        //                <div className={`${classes.animediv} anidiv`}>
        //                  <Typography style={{fontFamily: 'Rubik'}} className={classes.clienttext} >
        //                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, natus nemo. Tempora excepturi adipisci est qui blanditiis impedit nemo! Vitae.
        //                  </Typography></div>
        //                <Typography style={{fontFamily: 'Rubik'}} className={classes.clientname} >
        //                  Siemens
        //                </Typography>
        //              </Paper>,


    ];








    return (

        <
        div className = { classes.maindiv } >
        <
        ReactFontLoader url = 'https://fonts.googleapis.com/css2?family=Rubik&display=swap%27' / >
        <
        ReactFontLoader url = 'https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap' / >
        <
        Typography className = { classes.h }
        variant = "h4"
        component = "h2" >
        <
        u style = {
            { fontFamily: 'Rubik' } }
        className = { classes.underline } > OUR CLIENTS < /u> <
        /Typography> {
            clientsToDisp.length ?
                <
                div style = {
                    { width: '80%', marginTop: '4vh' } } >
                <
                AliceCarousel autoWidth = { true }
            renderPrevButton = {
                () => ( < ArrowLeftIcon style = {
                        { background: '#09417c', color: 'white', borderRadius: '5px', padding: '1vh', fontSize: '4vh', fontWeight: 'bolder' } }
                    />)} renderNextButton={()=>(<ArrowRightIcon style={{background:'#09417c',color:'white',borderRadius:'5px',padding:'1vh',fontWeight:'bolder',fontSize:'4vh'}}/ > ) }
            autoPlayInterval = { 2000 }
            mouseTracking items = {
                clientsToDisp.map((client) => ( <
                    Paper elevation = { 13 }
                    className = { `${classes.card} card contanim` }

                    >


                    <
                    img className = { classes.image }
                    src = { client.selectedFile }
                    height = "150vh"
                    width = "280px"
                    alt = "" / >
                    <
                    div className = { `${classes.animediv} anidiv` } >
                    <
                    Typography style = {
                        { fontFamily: 'Rubik' } }
                    className = { classes.clienttext } > { client.description } <
                    /Typography></div >
                    <
                    Typography style = {
                        { fontFamily: 'Rubik' } }
                    className = { classes.clientname } > { client.name } <
                    /Typography> <
                    /Paper>
                ))
            }
            autoPlay = { true }
            infinite = { true }
            />

            <
            /div>:<Loading height={'20vh'}></Loading >
        } <
        /div>
    );
}

export default ClientCarousel;