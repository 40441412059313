export default (news = [], action) => {
    switch (action.type) {
        case 'FETCH_ALL_NEWS':
            return action.payload;
        case 'CREATE_NEWS':
            return [...news, action.payload];
        case 'DELETE_NEWS':
            return news.filter((newsData) => newsData._id != action.payload);
        default:
            return news;
    }
}