import * as api from "../api"

export const getClients = () => async(dispatch) => {
    try {
        const { data } = await api.fetchClients()
        dispatch({ type: 'FETCH_ALL', payload: data });
    } catch (error) {
        console.log(error);
    }

}

export const createPost = (client) => async(dispatch) => {
    try {
        const { data } = await api.createClients(client);
        dispatch({ type: 'CREATE', payload: data })

    } catch (error) {
        console.log(error)
    }
}

export const deleteClient = (id) => async(dispatch) => {
    try {
        await api.deleteClient(id);
    } catch (error) {
        dispatch({ type: 'DELETE', payload: id })
    }
}

// export const updatePost = (id, post) => async(dispatch) => {
//     try {
//         const { data } = await api.updatePost(id, post);
//         dispatch({ type: 'UPDATE', payload: data })
//     } catch (error) {
//         console.log(error)
//     }
// }