import * as api from "../api"

export const getBlogs = () => async(dispatch) => {
    try {
        const { data } = await api.fetchBlogs()
        dispatch({ type: 'FETCH_ALL_BLOG', payload: data });
    } catch (error) {
        console.log(error);
    }

}

export const createBlog = (blog) => async(dispatch) => {
    try {
        const { data } = await api.createBlogs(blog);
        dispatch({ type: 'CREATE_BLOG', payload: data })


    } catch (error) {

    }
}

export const deleteBlog = (id) => async(dispatch) => {
    try {
        await api.deleteBlog(id);
    } catch (error) {
        dispatch({ type: 'DELETE_BLOG', payload: id })
    }
}
export const deleteComment = (id, id1) => async(dispatch) => {
    try {

        await api.deleteComment(id, id1)

    } catch (error) {

    }
}

export const updateBlog = (id, post) => async(dispatch) => {
    try {

        const { data } = await api.updateBlog(id, post);

        dispatch({ type: 'UPDATE_BLOG', payload: data })
    } catch (error) {
        console.log(error)
    }
}