import {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';



// import AuthContext from "./contextComp";
import {useHistory} from "react-router-dom";
// import { getToken } from '../api';
// import {useC}
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      display:'block',
      marginLeft:'auto',
      marginRight:'auto',
      
    },

  },
}));

function App({auth,handleAuth}) {
  const history=useHistory();
  // const [auth,setAuth]=useContext(AuthContext);
  const admin = useSelector(state => state.admin)
  const token = useSelector(state => state.token);
  
  const classes = useStyles();
  // const [auth, setAuth] = useState(0)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);

  

  


  const handleUsernameChange=(e)=>{
    setUsername(e.target.value);
  }

  const handlePasswordChange=(e)=>{
    setPassword(e.target.value);
  }
  
  const submitHandler =(e)=>{
    e.preventDefault()
   
    if(username===admin[0].name&&password===admin[0].password){

      handleAuth(1)

    
      sessionStorage.setItem('token', JSON.stringify(token));
   
      setOpen(false)
      history.push("/admin/home")
      //window.location="/admin/home"
    }
    else{
      setOpen(true)

    }
  }


  return (
    <div className="App" style={{height:'100%',width:'100%',textAlign:'center'}}>

      <h4>VAAP Admin Sign In </h4>

      <form onSubmit={submitHandler} className={classes.root} noValidate autoComplete="off">
      <div>
      <TextField style={{marginTop:'30vh'}} id="admin-username" value={username}
          onChange={handleUsernameChange} label="Username" /></div>
      <div>
      <TextField id="admin-password" value={password}
      
          onChange={handlePasswordChange} type='password' label="Password" /></div>
      <div><Button type='submit' variant="contained">Login</Button></div>
     
    </form>
    {open&&<p style={{color:'red'}}>Username or Password incorrect :(</p>}
    

  
    </div>
  );
}

export default App;
