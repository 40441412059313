import { combineReducers } from 'redux'

import clients from "./clients";
import blogs from "./blogs";
import admin from "./admin";
import token from "./token";
import news from "./news";
export default combineReducers({
    clients,
    blogs,
    admin,
    token,
    news
})