import Head from "./components/head";
import FooterHandler from "./components/footerhandler";
import HomePage from "./components/homepage";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Blogdetails from './components/blogdetails'
import About from "./components/about-us";
import Projects from "./components/projects";

import Clientele from "./components/clientele";
import Form from "./components/Form";
import {useEffect,useState} from 'react'
import {useDispatch} from 'react-redux'
import {getClients} from './actions/clients';
import {getBlogs} from "./actions/blogs";
import {getAdmin} from "./actions/admin";
import {getToken} from "./actions/token"
import {getNews} from "./actions/news"
import Services from "./components/services";
import AdminLogin from "./components/admin";
import AdminHome from "./components/adminhome"
import News from "./components/news"
import Contact from "./components/contact"
import BlogForm from "./components/blogform"
import BlogLists from "./components/bloglist"
import NewsList from "./components/newslist"
import ClientList from "./components/clinetlist"
import useMediaQuery from '@material-ui/core/useMediaQuery'

// import AuthContext from "./components/contextComp"
import React from "react";

import Topheader from "./components/topheaer"
function App() {
  const match=useMediaQuery('(min-width:400px)')
  var dispatch= useDispatch();
   const [auth, setAuth] = useState(0);
  //  setAuth("true");
  useEffect(()=>{
    dispatch(getClients())
    dispatch(getBlogs())
    dispatch(getAdmin())
    dispatch(getToken())
    dispatch(getNews())

  })
  const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
  const handleAuth = (a) =>{
  
    setAuth(a)
  }





  return (
    // <AuthContext.Provider value={authHook}>

      <Router>
        {match&&<Topheader></Topheader>}
        <Head/>
            <Route exact path="/">
            <HomePage  />
            <FooterHandler/>
            </Route>
            
            <Route exact path="/admin/login">
              <AdminLogin auth={auth} handleAuth={handleAuth} />
              <FooterHandler/>
            </Route>
            <Route exact path="/admin/home">
              {userToken?<AdminHome auth={auth} />:<h1>Not Authorised For this Route</h1>}
              <FooterHandler/>
            </Route>
            
            <Route exact path="/blogs/:id">
              <Blogdetails  />
              <FooterHandler/>
            </Route>
            <Route exact path="/admin/blogs">
              {userToken?<BlogForm></BlogForm>:<h1>Not Authorised for this Route</h1>}
              <FooterHandler/>
            </Route>
            <Route exact path="/admin/blogs/view">
                {userToken?<BlogLists></BlogLists>:<h1>Not Authorised for this Route</h1>}
                <FooterHandler/>
            </Route>
            <Route exact path="/admin/news">
                {userToken?<News/>:<h1>Not Authorised For this Route</h1>}
                <FooterHandler/>
            </Route>
            <Route exact path="/admin/news/view">
                {userToken?<NewsList/>:<h1>Not Authorised For this Route</h1>}
                <FooterHandler/>
            </Route>
            <Route exact path="/about">
              <About/>
              <FooterHandler/>
            </Route>
            <Route exact path="/projects">
              <Projects/>
              <FooterHandler/>
            </Route>
            <Route exact path="/clientele">
              <Clientele />
              <FooterHandler/>
            </Route>
            <Route exact path="/admin/clients">
              {userToken?<Form/>:<h1>Not Authorised for this Route</h1>}
              <FooterHandler/>
              
            </Route>
            <Route exact path="/admin/clients/view">
              {userToken?<ClientList/>:<h1>Not Authorised for this Route</h1>}
              <FooterHandler/>
            </Route>

            <Route exact path="/services/:comp">
              <Services/>
              <FooterHandler/>
            </Route>
            <Route exact path="/contactus">
                <Contact/>

            </Route>

   
    </Router>
        

// </AuthContext.Provider>
        
 
  );
}

export default App;
