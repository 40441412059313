import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactFontLoader from 'react-font-loader';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
     
    },
  },
}));

export default function CircularIndeterminate({height}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
            <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik&display=swap%27' />
        <div style={{ height:height,
      width:'100%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      flexDirection:'column',
      fontFamily:'Rubik',
      background:'transparent'}}>
      <CircularProgress style={{color:'#093c73'}} />
      <p>Loading....</p>
      </div>
    </div>
  );
}
