import React, { useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import {createBlog} from "../actions/blogs";

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }));
const BlogForm =() =>{
    const [blogData, setblogData] = useState({ title: '', body: '', summary: ''});
    const dispatch = useDispatch();
    const blogs = useSelector(state => state.blogs)

    const [error, setError] = React.useState('')
      const clear = () => {
          // setcurrentId(null);
          setblogData({title: '', body: '', summary: ''});
        };
        const submitHandler=(e)=>{
            e.preventDefault()
   
            if(blogData.summary.length>300){
                setError('Summary length should be less than 300 characters.')
            }
            if(blogData.title.length>150){
                setError('Title length should be less than 150 characters.')
            }
            else{
                dispatch(createBlog(blogData));
                clear();

            }
        }
    const classes=useStyles()
    return(
        <div>
            <div>
        <form onSubmit={submitHandler} className={classes.root} noValidate autoComplete="off">
        <div>
        <TextField value={blogData.title}
        onChange={(e) => setblogData({ ...blogData, title: e.target.value })} required name="title" label="Blog Title" /></div>
        <div>
        <TextField
        label="Blog Summary"
        value={blogData.summary}
        onChange={(e) => setblogData({ ...blogData, summary: e.target.value })}
        multiline
        required
        rows={2}
        variant="outlined"
    /></div>
        <div>
        <TextField
        label="Blog Body"
        multiline
        required
        value={blogData.body}
        onChange={(e) => setblogData({ ...blogData, body: e.target.value })}
        rows={7}
        variant="outlined"
    /></div>
    <div><Button type='submit' variant="contained">Post</Button></div>
    <p style={{color:'red'}}>{error}</p>
        
    </form>
    
    </div>
    </div>
 );
}

export default BlogForm;