// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import DesignImage from '../assets/des.png';
// import SystemStudyImage from '../assets/SystemStudy.png'
// import mechanicalImage from '../assets/mechanicalImage.png'
// import pipingImage from '../assets/pipingImage.png'
// import civilImage from '../assets/civilImage.png'
// import instrumentationImage from '../assets/instrumentationImage.png'
// import SafetyImage from '../assets/safetyImage.png'
// import BuildingImage from '../assets/Build.png'
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import EnergyImage from '../assets/Ener.png'
// import RenewablesImage from '../assets/Renewable.png'
// import ReactFontLoader from 'react-font-loader';
// import Zoom from 'react-reveal/Zoom';




// const OurServices = () => {

//   const matches = useMediaQuery('(min-width:960px)');
//   const matches2 = useMediaQuery('(max-width:1450px)');
//   const matches3 = useMediaQuery('(min-width:1450px)');
//   console.log((matches&&matches2))

//   const test=()=>{
//     console.log('hi')
//   }

//     const useStyles = makeStyles((theme) => ({
//         container:{
//           marginTop:'5vh',
//           textAlign:'center',
//           marginBottom: '7vh',
//           overflowX:'hidden',
//           overflowY:'hidden',
//           paddingBottom: '4vh'
          
//         },
//         h:{
//          fontWeight: '1000',
//          color: '#006ad0', 
//          letterSpacing:'0.7vw'
//         },
//         root: {
//           flexGrow: 1,
//           marginLeft: '2vw',
//           marginRight: '2vw'
//         },
//         griditem:{
//           textAlign: 'center',
//           display: 'flex',
//           alignItems: 'center',
//           flexDirection: 'column'
          
//         },
//         papermobile: {
//           display: 'flex',
//           flexDirection: 'column',
//           padding: theme.spacing(2),
//           textAlign: 'center',
//           height: "100%",
//           width: '90vw',
//           overflowY: 'auto',
//           scrollbarWidth: 'thin',         
          
//         },
//         papertab: {
//           display: 'flex',
//           flexDirection: 'column',
//           padding: theme.spacing(2),
//           textAlign: 'center',
//           height: "100%",
//           width: '25vw',
//           overflowY: 'auto',
//           scrollbarWidth: 'thin',         
          
//         },
//         paperpc:{
//           display: 'flex',
//           flexDirection: 'column',
//           padding: theme.spacing(2),
//           textAlign: 'center',
//           height: "100%",
//           minWidth: '25vw',
//           overflowY: 'auto',
//           scrollbarWidth: 'thin',         
//         },
//         list:{
//           textAlign:'left',
//         },
//         heading:{
//           background:'#006ad0', 
//           color:'white',
//           padding: '1vh'
//         },
//         underline:{
//           marginTop: '100vh'
//         },
//         eneheading:{
//           // background:'#006ad0', 
//           color:'#0d2d59',
//           padding: '1vh'
//         }
        
//       }));

//       const classes = useStyles();

//     return ( 
//       <div className={classes.container}>
//         <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik&display=swap%27' />
//         <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap' />
//         <Typography className={classes.h} variant="h4" component="h2">
//             <u style={{fontFamily:'Rubik'}} className={classes.underline}>OUR SERVICES</u> 
//           </Typography>
//         <div style={{marginTop:'7vh'}} className={classes.root}>
//       {matches3&&
//       <Zoom>
//         <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
//       <Grid layout={'row'} container spacing={3}>
//         <Grid item className={`${classes.griditem} image`} md={3} onClick={()=>{window.location="/services/1"}}>
         
//         <img   src={DesignImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.paperpc}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Electrical Engineering Design 
//           </Typography>
//           <Divider/>
//             <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Load Schedule</li>
//                 <li>Single Line Diagrams</li>
//                 <li>Equipment Sizing</li>
//                 <li>Equiment Layouts</li>
//                 <li>Earthing & Lightning Protection</li>
//                 <li>Lighting System Design</li>
//                 <li>Cable routing Layouts</li>
//                 <li>Conduit Layouts</li>
//                 <li>Interconnection Schedule</li>
//                 <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
//                 <li>As Built Drawings</li>
//             </ul>
//         </Paper>
//         </Grid>

//         {/* <Grid item className={`${classes.griditem} image`} md={3} onClick={()=>{window.location="/services/2"}}>
         
//         <img   src={SafetyImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.paperpc}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Specialised Studies 
//           </Typography>
//           <Divider/>
//             <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>SAFOP/E-HAZOP</li>
//                 <li>Constructibility Review</li>
//                 <li>Preparation of SOP & MCPs</li>
//                 <li>Operation Philosophy and Workshops</li>
//             </ul>
//         </Paper>
//         </Grid> */}
        

//         <Grid  className={`${classes.griditem} image`} item onClick={()=>{window.location="/services/2"}} md={3}>
        
//         <img src={SystemStudyImage} alt='Design' width='200vw' height='200vh' />
//         <Paper  className={classes.paperpc}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//             Power System Studies
//           </Typography>
//           <Divider/>
//             <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//             <li>Load Flow Studies</li>
//                 <li>Short Circuit Studies</li>
//                 <li>Transient Stability</li>
//                 <li>Relay Co-ordination</li>
//                 <li>Dynamic Stability</li>
//                 <li>Motor Starting Analysis</li>
//                 <li>Harmonic Analysis</li>
//                 <li>Arc Flash Analysis</li>
//             </ul>
//         </Paper>
//         </Grid>
//         <Grid className={`${classes.griditem} image`} item onClick={()=>{window.location="/services/5"}} md={3}>
//         <img src={BuildingImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.paperpc}>
//           <Typography style={{fontFamily:'Rubik', background:'#006ad0', color:'white'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Building Services
//           </Typography>
//           <Divider/>          
//             <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Lighting Calculation</li>
//                 <li>Lighting Layout</li>
//                 <li>DB Schedule & SLD</li>
//                 <li>Conduit Layout</li>
//                 <li>Procurement & Installation of Switchgear, DG, MCC, Lighting,Small Power, etc.</li>
//             </ul>
//             <Typography style={{fontFamily:'Rubik'}} className={classes.eneheading} gutterBottom variant="h5" component="h2">
//             Energy Audit
//           </Typography>
//           <Divider/>          
//             <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Energy Survey & Analysis</li>
//                 <li>Energy Conservation Measures</li>
//             </ul>
//         </Paper>
//         </Grid>
//         <Grid className={`${classes.griditem} image`} item  onClick={()=>{window.location="/services/6"}} md={3}>
//         <img src={instrumentationImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.paperpc}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Instrumentation, Control & Automation 
//           </Typography>
//           <Divider/>
//             <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Instrument Schedule</li>
//                 <li>Specification, Data sheet & Philosophy Documents</li>
//                 <li>Input/output List</li>
//                 <li>Hook up Drawings</li>
//                 <li>Loop Drawings</li>
//                 <li>SADA Programming</li>
//                 <li>Automation Panel Drawing </li>
//                 <li>Cable block Diagrams</li>
//                 <li>Installation Drawings</li>
//                 <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
//                 <li>Cable Schedule and Interconnection Schedule</li>
//                 <li>Cable Routing Layout</li>
//             </ul>
//         </Paper>
//         </Grid>

        
//       </Grid></div></Zoom>}

//       {(matches&&matches2)&&
//       <Zoom>
//         <div  style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
//       <Grid layout={'row'} container spacing={3}>
//         <Grid item className={`${classes.griditem} image`} md={6} onClick={()=>{window.location="/services/1"}}>
         
//         <img   src={DesignImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.papertab}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Electrical Engineering Design 
//           </Typography>
//           <Divider/>
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Load Schedule</li>
//                 <li>Single Line Diagrams</li>
//                 <li>Equipment Sizing</li>
//                 <li>Equiment Layouts</li>
//                 <li>Earthing & Lightning Protection</li>
//                 <li>Lighting System Design</li>
//                 <li>Cable routing Layouts</li>
//                 <li>Conduit Layouts</li>
//                 <li>Interconnection Schedule</li>
//                 <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
//                 <li>As Built Drawings</li>
//             </ul> */}
//         </Paper>
//         </Grid>

//         <Grid item className={`${classes.griditem} image`} md={6} onClick={()=>{window.location="/services/1"}}>
         
//         <img   src={SafetyImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.papertab}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Specialised Studies 
//           </Typography>
//           <Divider/>
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>SAFOP/E-HAZOP</li>
//                 <li>Constructibility Review</li>
//                 <li>Preparation of SOP & MCPs</li>
//                 <li>Operation Philosophy and Workshops</li>
//             </ul> */}
//         </Paper>
//         </Grid>
        

//         <Grid  className={`${classes.griditem} image`} item onClick={()=>{window.location="/services/2"}} md={6}>
        
//         <img src={SystemStudyImage} alt='Design' width='200vw' height='200vh' />
//         <Paper  className={classes.papertab}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//             Power System Studies
//           </Typography>
//           <Divider/>
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//             <li>Load Flow Studies</li>
//                 <li>Short Circuit Studies</li>
//                 <li>Transient Stability</li>
//                 <li>Relay Co-ordination</li>
//                 <li>Dynamic Stability</li>
//                 <li>Motor Starting Analysis</li>
//                 <li>Harmonic Analysis</li>
//                 <li>Arc Flash Analysis</li>
//             </ul> */}
//         </Paper>
//         </Grid>
//         <Grid className={`${classes.griditem} image`} item onClick={()=>{window.location="/services/5"}} md={6}>
//         <img src={BuildingImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.papertab}>
//           <Typography style={{fontFamily:'Rubik', background:'#006ad0', color:'white'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Building Services
//           </Typography>
//           <Divider/>          
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Lighting Calculation</li>
//                 <li>Lighting Layout</li>
//                 <li>DB Schedule & SLD</li>
//                 <li>Conduit Layout</li>
//                 <li>Procurement & Installation of Switchgear, DG, MCC, Lighting,Small Power, etc.</li>
//             </ul> */}
//             {/* <Typography style={{fontFamily:'Rubik'}} className={classes.eneheading} gutterBottom variant="h5" component="h2">
//             Energy Audit
//           </Typography>
//           <Divider/>           */}
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Energy Survey & Analysis</li>
//                 <li>Energy Conservation Measures</li>
//             </ul> */}
//         </Paper>
//         </Grid>
//         <Grid className={`${classes.griditem} image`} item  onClick={()=>{window.location="/services/6"}} md={6}>
//         <img src={instrumentationImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.papertab}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Instrumentation, Control & Automation 
//           </Typography>
//           <Divider/>
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Instrument Schedule</li>
//                 <li>Specification, Data sheet & Philosophy Documents</li>
//                 <li>Input/output List</li>
//                 <li>Hook up Drawings</li>
//                 <li>Loop Drawings</li>
//                 <li>SADA Programming</li>
//                 <li>Automation Panel Drawing </li>
//                 <li>Cable block Diagrams</li>
//                 <li>Installation Drawings</li>
//                 <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
//                 <li>Cable Schedule and Interconnection Schedule</li>
//                 <li>Cable Routing Layout</li>
//             </ul> */}
//         </Paper>
//         </Grid>
//       </Grid></div></Zoom>}

//         {!matches&&
//       <Grid layout={'row'} container spacing={3}>
//         <Grid item className={classes.griditem} onClick={()=>{window.location="/services/1"}} md={12}>
//         <img src={DesignImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.papermobile}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Electrical Engineering Design   
//           </Typography>
//           <Divider/>
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Load Schedule</li>
//                 <li>Single Line Diagrams</li>
//                 <li>Equipment Sizing</li>
//                 <li>Equiment Layouts</li>
//                 <li>Earthing & Lightning Protection</li>
//                 <li>Lighting System Design</li>
//                 <li>Cable routing Layouts</li>
//                 <li>Conduit Layouts</li>
//                 <li>Interconnection Schedule</li>
//                 <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
//                 <li>As Built Drawings</li>
//             </ul> */}
//         </Paper>
        
//         </Grid>

//         <Grid item className={classes.griditem} onClick={()=>{window.location="/services/1"}} md={12}>
//         <img src={SafetyImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.papermobile}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Specialised Studies
//           </Typography>
//           <Divider/>
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>SAFOP/E-HAZOP</li>
//                 <li>Constructibility Review</li>
//                 <li>Preparation of SOP & MCPs</li>
//                 <li>Operation Philosophy and Workshops</li>
                
//             </ul> */}
//         </Paper>
        
//         </Grid>
//         <Grid  className={classes.griditem} item  onClick={()=>{window.location="/services/1"}} md={12}>
        
//         <img src={SystemStudyImage} alt='Design' width='200vw' height='200vh' />
//         <Paper  className={classes.papermobile}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//             Power System Studies
//           </Typography>
//           <Divider/>
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//             <li>Load Flow Studies</li>
//                 <li>Short Circuit Studies</li>
//                 <li>Transient Stability</li>
//                 <li>Relay Co-ordination</li>
//                 <li>Dynamic Stability</li>
//                 <li>Motor Starting Analysis</li>
//                 <li>Harmonic Analysis</li>
//                 <li>Arc Flash Analysis</li>
//             </ul> */}
//         </Paper>
//         </Grid>
//         <Grid className={classes.griditem} item onClick={()=>{window.location="/services/5"}} md={12}>
//         <img src={BuildingImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.papermobile}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Building Services
//           </Typography>
//           <Divider/>          
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Lighting Calculation</li>
//                 <li>Lighting Layout</li>
//                 <li>DB Schedule & SLD</li>
//                 <li>Conduit Layout</li>
//                 <li>Procurement & Installation of Switchgear, DG, MCC, Lighting,Small Power, etc.</li>
//             </ul> */}
//             {/* <Typography style={{fontFamily:'Rubik'}} className={classes.eneheading} gutterBottom variant="h5" component="h2">
//             Energy Audit
//           </Typography>
//           <Divider/>           */}
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//                 <li>Energy Survey & Analysis</li>
//                 <li>Energy Conservation Measures</li>
//             </ul> */}
//         </Paper>
//         </Grid>
//         <Grid className={classes.griditem} item onClick={()=>{window.location="/services/6"}} md={12}>
//         <img src={instrumentationImage} alt='Design' width='200vw' height='200vh' />
//         <Paper className={classes.papermobile}>
//           <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
//           Instrumentation, Control & Automation 
//           </Typography>
//           <Divider/>
//             {/* <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
//             <li>Instrument Schedule</li>
//                 <li>Specification, Data sheet & Philosophy Documents</li>
//                 <li>Input/output List</li>
//                 <li>Hook up Drawings</li>
//                 <li>Loop Drawings</li>
//                 <li>SADA Programming</li>
//                 <li>Automation Panel Drawing </li>
//                 <li>Cable block Diagrams</li>
//                 <li>Installation Drawings</li>
//                 <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
//                 <li>Cable Schedule and Interconnection Schedule</li>
//                 <li>Cable Routing Layout</li>
//             </ul> */}
//         </Paper>
//         </Grid>
//       </Grid>}
//     </div>
//     </div>
//      );
// }
 
// export default OurServices;


import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DesignImage from '../assets/des.png';
import SystemStudyImage from '../assets/SystemStudy.png'
import BuildingImage from '../assets/Build.png'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EnergyImage from '../assets/Ener.png'
import ReactFontLoader from 'react-font-loader';
import Zoom from 'react-reveal/Zoom';
import civilImage from '../assets/civilImage.png'




const OurServices = () => {

  const matches = useMediaQuery('(min-width:960px)');

    const useStyles = makeStyles((theme) => ({
        container:{
          marginTop:'5vh',
          textAlign:'center',
          marginBottom: '7vh',
          overflowX:'hidden',
          overflowY:'hidden',
          paddingBottom: '4vh'
          
        },
        h:{
         fontWeight: '1000',
         color: '#006ad0', 
         letterSpacing:'0.7vw'
        },
        root: {
          flexGrow: 1,
          marginLeft: '2vw',
          marginRight: '2vw'
        },
        griditem:{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
          
        },
        papermobile: {
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2),
          textAlign: 'center',
          height: "100%",
          width: '90vw',
          overflowY: 'auto',
          scrollbarWidth: 'thin',         
          
        },
        paperpc:{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2),
          textAlign: 'center',
          height: "100%",
          minWidth: '20.5vw',
          overflowY: 'auto',
          scrollbarWidth: 'thin',         
        },
        list:{
          textAlign:'left',
        },
        heading:{
          background:'#006ad0', 
          color:'white',
          padding: '1vh'
        },
        underline:{
          marginTop: '100vh'
        },
        eneheading:{
          // background:'#006ad0', 
          color:'#0d2d59',
          padding: '1vh'
        }
        
      }));

      const classes = useStyles();

    return ( 
      <div className={classes.container}>
        <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik&display=swap%27' />
        <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap' />
        <Typography className={classes.h} variant="h4" component="h2">
            <u style={{fontFamily:'Rubik'}} className={classes.underline}>OUR SERVICES</u> 
          </Typography>
        <div style={{marginTop:'7vh'}} className={classes.root}>
      {matches&&
      <Zoom>
      <Grid layout={'row'} container spacing={3}>
        <Grid item className={`${classes.griditem} image`} md={3} onClick={()=>{window.location="/services"}}>
         
        <img   src={DesignImage} alt='Design' width='200vw' height='200vh' />
        <Paper className={classes.paperpc}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
          Electrical Engineering Design 
          </Typography>
          <Divider/>
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
                <li>Load Schedule</li>
                <li>Single Line Diagrams</li>
                <li>Equipment Sizing</li>
                <li>Equiment Layouts</li>
                <li>Earthing & Lightning Protection</li>
                <li>Lighting System Design</li>
                <li>Cable routing Layouts</li>
                <li>Conduit Layouts</li>
                <li>Interconnection Schedule</li>
                <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
                <li>As Built Drawings</li>
            </ul>
        </Paper>
        </Grid>
        

        <Grid  className={`${classes.griditem} image`} item onClick={()=>{window.location="/services"}} md={3}>
        
        <img src={SystemStudyImage} alt='Design' width='200vw' height='200vh' />
        <Paper  className={classes.paperpc}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
            System Studies
          </Typography>
          <Divider/>
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
            <li>Load Flow Studies</li>
                <li>Short Circuit Studies</li>
                <li>Transient Stability</li>
                <li>Relay Co-ordination</li>
                <li>Dynamic Stability</li>
                <li>Motor Starting Analysis</li>
                <li>Harmonic Analysis</li>
                <li>Arc Flash Analysis</li>
            </ul>
            <Typography style={{fontFamily:'Rubik'}} className={classes.eneheading} gutterBottom variant="h5" component="h2">
            Building Services
          </Typography>
          <Divider/>          
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
            <li>Lighting Calculation</li>
                <li>Lighting Layout</li>
                <li>DB Schedule & SLD</li>
                <li>Conduit Layout</li>
                <li>Procurement & Installation of Switchgear, DG, MCC, Lighting,Small Power, etc.</li>
            </ul>
        </Paper>
        </Grid>
        <Grid className={`${classes.griditem} image`} item onClick={()=>{window.location="/services"}} md={3}>
        <img src={BuildingImage} alt='Design' width='200vw' height='200vh' />
        <Paper className={classes.paperpc}>
          <Typography style={{fontFamily:'Rubik', background:'#006ad0', color:'white'}} className={classes.heading} gutterBottom variant="h5" component="h2">
          SCADA & Automation
          </Typography>
          <Divider/>          
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
                <li>Input/output  tag list</li>
                <li>Cause & effect diagram</li>
                <li>PID loops for control valve & pumps</li>
                <li>Graphics, Faceplates, alarms etc for HMI</li>
                <li>PLC panel design</li>
                <li>SCADA/PLC Programming</li>
            </ul>
            <Typography style={{fontFamily:'Rubik'}} className={classes.eneheading} gutterBottom variant="h5" component="h2">
            Energy Audit
          </Typography>
          <Divider/>          
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
                <li>Energy Survey & Analysis</li>
                <li>Energy Conservation Measures</li>
            </ul>
        </Paper>
        </Grid>
        <Grid className={`${classes.griditem} image`} item  onClick={()=>{window.location="/services"}} md={3}>
        <img src={EnergyImage} alt='Design' width='200vw' height='200vh' />
        <Paper className={classes.paperpc}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
          Instrumentation & Control Design 
          </Typography>
          <Divider/>
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
                <li>Instrument Schedule</li>
                <li>Specification, Data sheet & Philosophy Documents</li>
                <li>Input/output List</li>
                <li>Hook up Drawings</li>
                <li>Loop Drawings</li>
                <li>Cable block Diagrams</li>
                <li>Installation Drawings</li>
                <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
                <li>Cable Schedule and Interconnection Schedule</li>
                <li>Cable Routing Layout</li>
            </ul>
        </Paper>
        </Grid>

        <Grid className={`${classes.griditem} image`} item  onClick={()=>{window.location="/services"}} md={3}>
        <img src={civilImage} alt='Design' width='200vw' height='200vh' />
        <Paper className={classes.paperpc}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
          Structural 
          </Typography>
          <Divider/>
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
                <li>Advance Bill of Materials</li>
                <li>Anchor Bolt Layout & Details</li>
                <li>Erection / GA Drawings</li>
                <li>Shop Drawings</li>
                <li>NC Data</li>
                <li>Material List & Bolt list Reports </li>
                <li>BOQ & Bought Out Lists</li>
                <li>KISS / Fabtrol Reports</li>
                <li>IFC  files for integration</li>
                <li>3D DWG model</li>
            </ul>
        </Paper>
        </Grid>
      </Grid></Zoom>}

        {!matches&&
      <Grid layout={'row'} container spacing={3}>
        <Grid item className={classes.griditem} onClick={()=>{window.location="/services"}} md={3}>
        <img src={DesignImage} alt='Design' width='200vw' height='200vh' />
        <Paper className={classes.papermobile}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
          Electrical Engineering Design   
          </Typography>
          <Divider/>
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
                <li>Load Schedule</li>
                <li>Single Line Diagrams</li>
                <li>Equipment Sizing</li>
                <li>Equiment Layouts</li>
                <li>Earthing & Lightning Protection</li>
                <li>Lighting System Design</li>
                <li>Cable routing Layouts</li>
                <li>Conduit Layouts</li>
                <li>Interconnection Schedule</li>
                <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
                <li>As Built Drawings</li>
            </ul>
        </Paper>
        
        </Grid>
        <Grid  className={classes.griditem} item  onClick={()=>{window.location="/services"}} md={3}>
        
        <img src={SystemStudyImage} alt='Design' width='200vw' height='200vh' />
        <Paper  className={classes.papermobile}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
            System Studies
          </Typography>
          <Divider/>
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
            <li>Load Flow Studies</li>
                <li>Short Circuit Studies</li>
                <li>Transient Stability</li>
                <li>Relay Co-ordination</li>
                <li>Dynamic Stability</li>
                <li>Motor Starting Analysis</li>
                <li>Harmonic Analysis</li>
                <li>Arc Flash Analysis</li>
            </ul>
            <Typography style={{fontFamily:'Rubik'}} className={classes.eneheading} gutterBottom variant="h5" component="h2">
            Building Services
          </Typography>
          <Divider/>          
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
            <li>Lighting Calculation</li>
                <li>Lighting Layout</li>
                <li>DB Schedule & SLD</li>
                <li>Conduit Layout</li>
                <li>Procurement & Installation of Switchgear, DG, MCC, Lighting,Small Power, etc.</li>
            </ul>
        </Paper>
        </Grid>
        <Grid className={classes.griditem} item onClick={()=>{window.location="/services"}} md={3}>
        <img src={BuildingImage} alt='Design' width='200vw' height='200vh' />
        <Paper className={classes.papermobile}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
          SCADA & Automation
          </Typography>
          <Divider/>          
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
            <li>Input/output  tag list</li>
                <li>Cause & effect diagram</li>
                <li>PID loops for control valve & pumps</li>
                <li>Graphics, Faceplates, alarms etc for HMI</li>
                <li>PLC panel design</li>
                <li>SCADA/PLC Programming</li>
            </ul>
            <Typography style={{fontFamily:'Rubik'}} className={classes.eneheading} gutterBottom variant="h5" component="h2">
            Energy Audit
          </Typography>
          <Divider/>          
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
                <li>Energy Survey & Analysis</li>
                <li>Energy Conservation Measures</li>
            </ul>
        </Paper>
        </Grid>
        <Grid className={classes.griditem} item onClick={()=>{window.location="/services"}} md={3}>
        <img src={EnergyImage} alt='Design' width='200vw' height='200vh' />
        <Paper className={classes.papermobile}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
          Instrumentation & Control Design 
          </Typography>
          <Divider/>
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
            <li>Instrument Schedule</li>
                <li>Specification, Data sheet & Philosophy Documents</li>
                <li>Input/output List</li>
                <li>Hook up Drawings</li>
                <li>Loop Drawings</li>
                <li>Cable block Diagrams</li>
                <li>Installation Drawings</li>
                <li>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</li>
                <li>Cable Schedule and Interconnection Schedule</li>
                <li>Cable Routing Layout</li>
            </ul>
        </Paper>
        </Grid>
          
        <Grid className={classes.griditem} item onClick={()=>{window.location="/services"}} md={3}>
        <img src={civilImage} alt='Design' width='200vw' height='200vh' />
        <Paper className={classes.papermobile}>
          <Typography style={{fontFamily:'Rubik'}} className={classes.heading} gutterBottom variant="h5" component="h2">
          Structural 
          </Typography>
          <Divider/>
            <ul style={{textAlign:'left', fontFamily:'Rubik'}}>
            <li>Advance Bill of Materials</li>
                <li>Anchor Bolt Layout & Details</li>
                <li>Erection / GA Drawings</li>
                <li>Shop Drawings</li>
                <li>NC Data</li>
                <li>Material List & Bolt list Reports </li>
                <li>BOQ & Bought Out Lists</li>
                <li>KISS / Fabtrol Reports</li>
                <li>IFC  files for integration</li>
                <li>3D DWG model</li>
            </ul>
        </Paper>
        </Grid>  
      </Grid>}
    </div>
    </div>
     );
}
 
export default OurServices;