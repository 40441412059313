import axios from 'axios'

var url = "https://vaap-backend.onrender.com/clients";
var blogurl = "https://vaap-backend.onrender.com/blogs";
var adminurl = "https://vaap-backend.onrender.com/admin";
var newsurl = "https://vaap-backend.onrender.com/news";

export const fetchClients = () => axios.get(url);
export const createClients = (newPost) => axios.post(url, newPost)
export const deleteClient = (id) => axios.delete(`${url}/${id}`);
export const fetchBlogs = () => axios.get(blogurl);
export const createBlogs = (newPost) => axios.post(blogurl, newPost)
export const updateBlog = (currentId, newPost) => axios.patch(blogurl + "/" + currentId, newPost)
export const deleteBlog = (id) => axios.delete(`${blogurl}/${id}`);
export const deleteComment = (id, id1) => axios.delete(`${blogurl}/${id}/${id1}`);
export const fetchAdmin = () => axios.get(adminurl);
export const getToken = () => axios.get(adminurl + "/createToken");
export const getNews = () => axios.get(newsurl);
export const postNews = (newPost) => axios.post(newsurl, newPost)
export const deleteNews = (id) => axios.delete(`${newsurl}/${id}`)

// export const uploadFile = (data) =>axios.post(newsurl+"/upload");