import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {deleteClient,getClients} from "../actions/clients"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));


export default function InteractiveList({arg}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const clients = useSelector(state => state.clients)

  const handleOnClick =(client) =>{


    dispatch(deleteClient(client._id));

  }
  useEffect(()=>{

    dispatch(getClients())
  

  })
  return (
    <div style={{height:'70vh',overflowY:'scroll'}} className={classes.root}>
        
        <div className={classes.demo}>
            <List>
             {clients.map((client,index)=>
                 <ListItem key={index}>
                 <ListItemText
                   primary={client.name}
                   secondary={client.description}
                 />
                 <ListItemSecondaryAction>
                   <IconButton onClick={()=>{handleOnClick(client)}} edge="end" aria-label="delete">
                     <DeleteIcon />
                   </IconButton>
                 </ListItemSecondaryAction>
               </ListItem>
             )}
                
            </List>
          </div>

    </div>
  );
}
