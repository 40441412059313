import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import ReactFontLoader from 'react-font-loader';
import IconBreadcrumbs from './breadcrumb';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TextField from '@material-ui/core/TextField';
import axios from "axios"
// import Map from './map';

// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

// import Map from 'pigeon-maps'
// import Marker from 'pigeon-marker'
// import Overlay from 'pigeon-overlay'
import { Button } from '@material-ui/core';
// import {
//     ComposableMap,
//     Geographies,
//     Geography,
//     Marker,
//     ZoomableGroup
//   } from "react-simple-maps"









const Contacts =  () => {

    const [position, setPosition] = useState({ coordinates: [-74.006, 40.7128], zoom: 1 });
    const [email, setemail] = useState({ name: '', subject: '', email: '',body:''});
    const clear = () => {
        // setcurrentId(null);
        setemail({name: '', subject: '', email: '',body:''});
      };
    const submitHandler = (e) =>{
        e.preventDefault();

        // const formData = new FormData();
        // formData.append('file',news.file)
        try {
          const res = axios.post("https://vaap-energy.herokuapp.com/contact",email)
        } catch (error) {
        console.log(error)
        }
        clear();
    }
    function handleZoomIn() {
      if (position.zoom >= 8) return;
      setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
    }
  
    function handleZoomOut() {
      if (position.zoom <= 1) return;
      setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
    }
  
    function handleMoveEnd(position) {
      setPosition(position);
    }

    const useStyles = makeStyles((theme) => ({
        textbox: {
            paddingTop: '10px',
            paddingBottom: '10px',
        },
        mapouter:{position:`relative`,textAlign:`right`,height:`500px`,width:`600px`},
        gmap_canvas: {overflow:'hidden',background:'none !important',height:'500px',width:'600px'}
    }));


    const classes = useStyles();
    const matches = useMediaQuery('(min-width:550px)');
    const matche1 = useMediaQuery('(min-width:550px)');

//     const ref = useRef(null);
//   const [map, setMap] = useState(null);
//   const map1 = new mapboxgl.Map({
//     container: ref.current,
//     style: "mapbox://styles/mapbox/streets-v11",
//     center: [25, 25],
//     zoom: 1
//   });
//   setMap(map1);
// const [lng, setLng] = useState(-70.9);
// const [lat, setLat] = useState(42.35);
// const [zoom, setZoom] = useState(9);
  
// const mapContainer = React.useRef()

// const map = new mapboxgl.Map({
//     container: mapContainer.current,
//     style: 'mapbox://styles/mapbox/streets-v11',
//     center: [this.state.lng, this.state.lat],
//     zoom: this.state.zoom})

    return (

        
        

       

        <div style={{ paddingBottom: '4vh', overflowX: 'hidden' }}>
            <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
   integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
   crossOrigin=""/>
   <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
   integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
   crossOrigin=""></script>
            <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap' />
            <IconBreadcrumbs page={'Contact Us'} />
            <div className={classes.container1}>
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item style={{ display: 'flex', textAlign: 'left', paddingLeft: '5%', paddingRight: '10%', color: '#505050' }} xs={matches ? 6 : 12}>
                            <div>
                                <p><u><b>CHENNAI, INDIA</b></u></p>
                                <p><b>VAAP ENERGY Pvt. Ltd.</b></p>
                                <p>
                                #201, 6th Cross Street <br></br> Saraswathi Nagar, Thirumullaivoyal<br></br> Chennai – 600062, Tamil Nadu.
                                  <br></br>  Phone : +91 7358460244<br />
                                    Email : marketing@vaapenergy.com<br />
                                    Website : www.vaapenergy.com <br />
                                </p>
                            </div>
                        </Grid>
                        <Grid  item style={{ display: 'flex', textAlign: 'left', paddingLeft: '5%', paddingRight: '10%', color: '#505050' }} xs={matches ? 6 : 12}>
                        <div id='mapid' style={{marginTop:'1vh',marginBottom:'1vh',marginRight:'1vw',paddingRight:'1vw'}}>
                        
      {/* <Map center={[13.132557464775086, 80.14008731758875]} zoom={12} width={600} height={400}>
    <Marker anchor={[13.132557464775086, 80.14008731758875]} payload={1} onClick={({ event, anchor, payload }) => {}} />

    <Overlay anchor={[13.132557464775086, 80.14008731758875]} offset={[120, 79]}>
     
    </Overlay>
  </Map> */}
  <div style={{}}>
      <iframe scrolling="no" src="https://maps.google.com/maps?q=13.132677,%2080.140115&t=&z=15&ie=UTF8&iwloc=&output=embed" width="110%" height="400vw" frameborder="0"></iframe>
     
</div>
</div> </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item style={{ display: 'flex', textAlign: 'left', paddingLeft: '5%', color: '#505050' }} xs={matches ? 6 : 12}>
                            <div>
                                <p><u><b>UAE</b></u></p>
                                <p><b>VAAP ENERGY Engineers & Consultant FZE LLC.</b></p>
                                <p>
                                    PO Box : 45290, Ajman, UAE.<br />
                                    Phone : +971 50 5743500<br />
                                    Email : cmk@vaapenergy.com<br />
                                    Website : www.vaapenergy.com <br />
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item style={{ display: 'flex', textAlign: 'left', paddingLeft: '5%', color: '#505050' }} xs={matches ? 6 : 12}>
                            <form onSubmit={submitHandler}>
                                <div>
                                    <div className={classes.textbox}>
                                        <TextField
                                        name="name"
                                            required
                                            value={email.name} 
                                            onChange={(e) => setemail({ ...email, name: e.target.value })}
                                            id="outlined-required"
                                            style={{ width: '44vh' }}
                                            label="Your Name"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className={classes.textbox}>
                                        <TextField
                                        name="email"
                                        value={email.email} 
                                            onChange={(e) => setemail({ ...email, email: e.target.value })}
                                            required
                                            id="outlined-required"
                                            style={{ width: '44vh' }}
                                            label="Your Mail"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className={classes.textbox}>
                                        <TextField
                                        name="subject"
                                        value={email.subject} 
                                            onChange={(e) => setemail({ ...email, subject: e.target.value })}
                                            required
                                            id="outlined-required"
                                            style={{ width: '44vh' }}
                                            label="Subject"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className={classes.textbox}>
                                        <TextField
                                            name="body"
                                            value={email.body} 
                                            onChange={(e) => setemail({ ...email, body: e.target.value })}
                                            multiline
                                            rows={4}
                                            style={{ width: '44vh' }}
                                            id="outlined-required"
                                            label="Your message"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                                <Button type='submit' style={{background:'#09417c',color:'white'}}>Send</Button>
                            </form>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )

}
export default Contacts;