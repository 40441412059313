import React,{Fragment,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FileBase from 'react-file-base64';
import {TextField,Button} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {createNews} from "../actions/news";
import {useHistory} from "react-router-dom"
 const News =() => {
   const history=useHistory();
  const dispatch = useDispatch();
     const [news, setnews] = useState({newstitle:'',file:''});
     const clear = () => {
      // setcurrentId(null);
      setnews({newstitle: '', file: ''});
    };
  
     const handleSubmit = async (e) => {
        e.preventDefault();

        // const formData = new FormData();
        // formData.append('file',news.file)
        // try {
        //   const res = await axios.post("http://localhost:5000/upload",formData,{
        //     headers:{
        //       'Content-Type':'multipart/form-data'
        //     }
        //   })
        // } catch (error) {
        //   if(error.response.status ===500)
        // 
        dispatch(createNews(news));

        // clear();
        history.push("/admin/home");
        // window.location="/admin/news"
       
        
      };
    
    // const classes = useStyles();
    return (
        <div >
            <Fragment>
            <form autoComplete="off" noValidate  onSubmit={handleSubmit}>
            <div>
        <TextField label="newstitle" style={{ margin: 8 }} placeholder="News Title"  name="newstitle" fullWidth margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setnews({ ...news, newstitle: e.target.value })}
        
          variant="outlined"
        />
      <div style={{width: '97%',margin: '10px 0'}}><FileBase type="file" required multiple={false} onDone={({ base64 }) => setnews({ ...news, file: base64 })} /></div>
        </div>
        <Button style={{marginBottom: 10,}} variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button>
        </form>
        </Fragment>
        </div>
            )
}

export default News;

    