import React, { useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';

// import AuthContext from "./contextComp";


const Adminhome = () => {
  
    // const auth=useContext(AuthContext)[0];
  
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
  
   




    function handleOnclick(){

      sessionStorage.clear();
      window.location="/"

    }

    return ( 
        <div>
        {!userToken&&<h3>Not authorised</h3>}
        <Button variant="outlined" onClick={handleOnclick} color="secondary">
          Logout
        </Button>
        <div>
        <Button variant="outlined"  onClick={()=>{window.location='/admin/blogs'}} color="primary">
          Blog
        </Button>
        <div>
        <Button variant="outlined"  onClick={()=>{window.location='/admin/blogs/view'}} color="primary">
          View Blog
        </Button>
        </div>
        </div>
        <div>
        <Button variant="outlined"  onClick={()=>{window.location='/admin/clients'}} color="primary">
          client
        </Button>
        </div>
        <div>
        <Button variant="outlined"  onClick={()=>{window.location='/admin/clients/view'}} color="primary">
          View Client
        </Button>
        </div>
        <div>
        <Button variant="outlined"  onClick={()=>{window.location='/admin/news'}} color="primary">
          News
        </Button>
        <div>
        <Button variant="outlined"  onClick={()=>{window.location='/admin/news/view'}} color="primary">
          View News
        </Button>
        </div>
        </div>
        </div>
     
     );
}
 
export default Adminhome;