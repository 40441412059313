
import React, { useState } from 'react';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import { useDispatch} from 'react-redux';
import FileBase from 'react-file-base64';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
// import useStyles from './styles';
import {createPost} from "../actions/clients"
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
    },
},
paper: {
    padding: theme.spacing(2),
},
form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
},
fileInput: {
    width: '97%',
    margin: '10px 0',
},
buttonSubmit: {
    marginBottom: 10,
},
}));

const Form = () => {
  const [clientData, setclientData] = useState({ name: '', description: '', selectedFile: '',country:'' ,display:'',pdisplay:''});
  const dispatch = useDispatch();
  const classes = useStyles();
 



  const clear = () => {
    // setcurrentId(null);
    setclientData({name: '', description: '', selectedFile: ''});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   
      dispatch(createPost(clientData));
      clear();
    
  };

  return (
    <Paper className={classes.paper}>
      <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
        <Typography variant="h6">Create Client</Typography>
        <TextField name="name" required variant="outlined" label="Name" fullWidth value={clientData.name} onChange={(e) => setclientData({ ...clientData, name: e.target.value })} />
        <TextField name="description" required variant="outlined" label="Description" fullWidth multiline rows={4} value={clientData.description} onChange={(e) => setclientData({ ...clientData, description: e.target.value })} />
        <div className={classes.fileInput}><FileBase type="file" required multiple={false} onDone={({ base64 }) => setclientData({ ...clientData, selectedFile: base64 })} /></div>
        <InputLabel id="demo-simple-select-label">Abroad/Indian</InputLabel>
        <Select required name="country" labelId="demo-simple-select-label" id="demo-simple-select" value={clientData.country} onChange={(e)=> setclientData({...clientData,country:e.target.value})}>
          <MenuItem value={1}>Abroad</MenuItem>
          <MenuItem value={0}>Indian</MenuItem>
        </Select>
        <div></div>
        <InputLabel id="demo-simple-select-label">Display in Home</InputLabel>
        <Select required name="country" labelId="demo-simple-select-label" id="demo-simple-select" value={clientData.display} onChange={(e)=> setclientData({...clientData,display:e.target.value})}>
          <MenuItem value={true}>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label">Displayy in Project Page</InputLabel>
     <Select required name="pdisplay" labelId="demo-simple-select-label" id="demo-simple-select" value={clientData.pdisplay} onChange={(e)=> setclientData({...clientData,pdisplay:e.target.value})}>
          <MenuItem value={true}>Display in Project Page</MenuItem>
          <MenuItem value={false}>Dont Display in Project Page</MenuItem>
        </Select>
        <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button>
        <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Clear</Button>
      </form>
    </Paper>
  );
};

export default Form;