import React from 'react'
import ReactFontLoader from 'react-font-loader';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneIcon from '@material-ui/icons/Phone'
import MailIcon from '@material-ui/icons/Mail';

const Topheader = () => {
    return (
        <div>
            <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik&display=swap%27' />
            <div style={{display:'flex',flexDirection:'row-reverse', fontFamily:'Rubik', backgroundColor:'#0d2d59',color:'wheat',paddingLeft:'1vw',paddingRight:'1vw',paddingTop:'0.2vh',paddingBottom:'0.2vh'}} >
       <div style={{display:'flex'}}><div style={{marginTop:'0.5vh'}}><MailIcon style={{paddingTop:'0.2vh',paddingRight:'0.2vw'}}></MailIcon></div><div style={{paddingTop:'0.8vh',paddingRight:'0.5vw'}}>cmk@vaapenergy.com  |</div><div style={{marginTop:'0.5vh'}}><PhoneIcon style={{paddingTop:'0.2vh'}}></PhoneIcon></div><div style={{paddingTop:'0.8vh',paddingRight:'0.5vw'}}>+91-7358449523  |</div><div><LinkedInIcon style={{paddingTop:'0.5vh'}} onClick={() => window.location.href="https://www.linkedin.com/in/vaap-energy-private-limited-967840181"} /> </div></div>
      </div>
        </div>
    )
}

export default Topheader
