// import React,{useEffect} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import ReactFontLoader from 'react-font-loader';
// import IconBreadcrumbs from './breadcrumb';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useParams} from 'react-router-dom';
// import image from '../assets/ServicesBg1.png'
// import { Element, scrollToComponent} from 'react-scroll'

// const Services = () => {
//     const {comp}=useParams();
//     var Scroll   = require('react-scroll');
//     var Element  = Scroll.Element;
//     var scroller = Scroll.scroller;

//     console.log(comp)
//     useEffect(() => {
//         // Update the document title using the browser API
//         scrollToElement();
//       });
//     // scrollToElement = () => this.testRef.current.scrollIntoView();
    
//     const scrollToElement=()=>{
//         if (comp==='0'){
//             console.log('')
//         }
//         if (comp==='1'){
//             scroller.scrollTo('1', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//         if (comp==='2'){
//             scroller.scrollTo('2', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//         if (comp==='3'){
//             scroller.scrollTo('3', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//         if (comp==='4'){
//             scroller.scrollTo('4', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//         if (comp==='5'){
//             scroller.scrollTo('5', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//         if (comp==='6'){
//             scroller.scrollTo('6', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//         if (comp==='7'){
//             scroller.scrollTo('7', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//         if (comp==='8'){
//             scroller.scrollTo('8', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//         if (comp==='9'){
//             scroller.scrollTo('9', {
//                 duration: 1500,
//                 delay: 100,
//                 smooth: true,
//                 offset: -50, // Scrolls to element + 50 pixels down the page
//               })
//         }
//     }

//     const useStyles = makeStyles((theme) => ({
//         // bdy:{
//         //     marginLeft:'35px',#53bcc0
//         // },
//     }));

    

//     const classes = useStyles();
//     const matches = useMediaQuery('(min-width:700px)');
//     return (
//         <div className='0' style={{paddingBottom:'4vh'}}>
//             <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap' />
//             <IconBreadcrumbs page={'Services'} />
//             <div className={classes.container1}>
//                 <div className={classes.root}>
//                     <Grid container spacing={3}>
//                         {/* <Grid item xs={6}>
//                             <img width='100%' height='100%' src={"http://vaapenergy.com/wp-content/uploads/2020/03/Industrial.jpg"} />
//                         </Grid> */}
//                         <Grid item style={{ display: 'flex',   alignitems:'flex-start' }} xs={12}>
//                             <img src={image} alt='Services' style={{ width: '100%' }}></img>
//                         </Grid>
//                         <Grid className='1' item style={{ display: 'flex',   alignitems:'flex-start' }} xs={matches?4 : 12}>

//                         <Element name="1"><div className={classes.textbody}>
//                                 {/* <Typography variant="h5" className={classes.head} >Vaap Energy</Typography> */}

//                                 <div className={classes.bdy} >
//                                     <Typography variant="h6" style={{ color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>CONCEPT- FEED- PRIMARY & DETAILED ENGINEERING SERVICES</Typography>

//                                     <ul className={classes.lis} aria-label="contacts">
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Load Schedule</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Single Line Diagrams</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030' }} variant="body2">  <span>Equipment Sizing & Selection</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Adequacy Checks of Equipment and Cables</span></Typography>
//                                         </ li>

//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Protection and Metering SLD</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Substation Layout Plan and Sections</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Substation Clearance Diagrams</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Erection Key Diagram</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Earthing & Lightning Protection</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Lighting System Design</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Procurement Support</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Conduit Layouts</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Cable Routing and Schedule</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Drum Schedule</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Control Room Equipment Layout</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>SCMS, PMS and SCADA System</span></Typography>
//                                         </ li>
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Project Bill of Materials</span></Typography>
//                                         </ li>
//                                     </ul>
//                                 </div>
//                             </div></Element>
//                         </Grid>
//                         <Grid className='2' item style={{ display: 'flex',   alignitems:'flex-start' }} xs={matches?4 : 12}>
//                         <Element name="2">
//                             <div className={classes.textbody}>
//                                 {/* <Typography variant="h5" className={classes.head} >Vaap Energy</Typography> */}

//                                 <div className={classes.bdy} >
//                                     <Typography variant="h6" style={{ color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>POWER SYSTEM STUDIES</Typography>
//                                     <Typography variant="body1" style={{ color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw' }}>Using Latest ETAP Software Package, Power system studies covers the following</Typography>
//                                     < ul className={classes.lis} aria-label="contacts">
//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Load Flow studies</span></Typography>
//                                         </ li>

//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Short Circuit studies</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Transient Stability</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Relay Co-ordination</span></Typography>
//                                         </ li>

//                                         < li >
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Dynamic Stability</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Motor starting Analysis</span></Typography>
//                                         </ li>
//                                         < li >

//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Harmonic Analysis</span></Typography>
//                                         </ li>
//                                         < li>
//                                             <Typography style={{ color: '#303030'  }} variant="body2">  <span>Arc Flash Analysis</span></Typography>
//                                         </ li>

//                                     </ ul>
//                                 </div>
//                             </div></Element>
//                         </Grid>
                        
//                         <Grid className='3' item style={{ display: 'flex',   alignitems:'flex-start' }} xs={matches?4 : 12}>
//                         <Element name="3"><div className={classes.bdy} >
//                                 <Typography variant="h6" style={{ color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh'}}>OWNER'S ENGINEER SERVICES</Typography>

//                                 < ul className={classes.lis} aria-label="contacts">
//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Conceptual design and engineering</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Project cost estimate and schedule</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Preparation of Tender for EPC scope of work</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Post bid clarifications</span></Typography>
//                                     </ li>

//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Bid evaluation and recommendations</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Design review</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Review and approval of design documents</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Review and approval of type test reports</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>FAT at manufacturers’ works</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030' }} variant="body2">  <span>Development & review of O&M procedure</span></Typography>
//                                     </ li>

//                                 </ ul>
//                             </div></Element>
//                         </Grid>



//                         <Grid className='4' item style={{ display: 'flex',   alignitems:'flex-start' }} xs={matches?4 : 12}>
//                         <Element name="4"><div className={classes.bdy} >
//                                 <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>ENERGY AUDITS</Typography>

//                                 < ul className={classes.lis} style={{marginLeft:'0px'}} aria-label="contacts">
//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Preliminary Energy Use Analysis</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Walk Through Analysis</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Energy Survey Analysis</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Detailed Analysis</span></Typography>
//                                     </ li>

//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Energy Conservation Measures</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030' }} variant="body2">  <span>Energy Saving & Improvement Opportunities</span></Typography>
//                                     </ li>


//                                 </ ul>
//                             </div>
//                             </Element>
//                         </Grid>

//                         <Grid className='5' item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
//                         <Element name="5"><div className={classes.bdy} >
//                                 <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>BUILDING SERVICES</Typography>

//                                 < ul className={classes.lis} aria-label="contacts">
//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Lighting calculations</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Lighting layouts</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>DB schedules and single line diagram</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Conduit layouts</span></Typography>
//                                     </ li>

//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Supply, Installation & Commissioning of DG, LV SWBD, MCC, UPS,<br/>Lighting etc.</span></Typography>
//                                     </ li>
                                    

//                                 </ ul>
//                             </div>
//                             </Element>
//                         </Grid>
//                         <Grid className='6' item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
//                         <Element name="6"><div className={classes.bdy} >
//                                 <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>Instrumentation, Control and Automation</Typography>

//                                 < ul className={classes.lis} aria-label="contacts">
//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Instrument Schedule</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Specification, Data sheet & Philosophy Documents</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Input/output List</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Hook up Drawings</span></Typography>
//                                     </ li>

//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Loop Drawings</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Cable block Diagrams</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Installation Drawings</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Cable Schedule and Interconnection Schedule</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Cable Routing Layout</span></Typography>
//                                     </ li>

//                                 </ ul>
//                             </div>
//                             </Element>
//                         </Grid>


//                         <Grid className='7' item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
//                         <Element name="7"><div className={classes.bdy} >
//                                 <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>Piping & Layout </Typography>

//                                 < ul className={classes.lis} aria-label="contacts">
//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Design Basis</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Review of Plot Plan</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Updating Piping Specification</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Pipe Wall Thickness Calculation</span></Typography>
//                                     </ li>

//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Piping Study/Piping Layout</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Nozzle Orientation Drawings</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>=Isometric Preparation/Checking</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Support Design/Support Layout</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Material Take Off (M.T.O)</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Stress Analysis</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>3D Modelling</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Equipment/Piping Modelling</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Support Marking</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>AIV/FIV Analysis</span></Typography>
//                                     </ li>

//                                 </ ul>
//                             </div>
//                             </Element>
//                         </Grid>

//                         <Grid className='8' item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
//                         <Element name="8"><div className={classes.bdy} >
//                                 <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>Civil & Structural </Typography>

//                                 < ul className={classes.lis} aria-label="contacts">
//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Design Basis</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Analysis-Static, Dynamic Structural Steel Design</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Design Calculations for Foundation, Pipe Racks, etc.</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Structural Data Sheet</span></Typography>
//                                     </ li>

//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Anchor Bolt, Handrail, etc.</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Equipment Foundation Design and Drawings</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Equipment Support Structure</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Design and Drawings</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Material Take Off</span></Typography>
//                                     </ li>
                                

//                                 </ ul>
//                             </div>
//                             </Element>
//                         </Grid>

//                         <Grid className='9' item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
//                         <Element name="9"><div className={classes.bdy} >
//                                 <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>Mechanical </Typography>

//                                 < ul className={classes.lis} aria-label="contacts">
//                                     < li >
//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Design Basis</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Review of plot plan/equipment</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Layout</span></Typography>
//                                     </ li>
//                                     < li >

//                                         <Typography style={{ color: '#303030'  }} variant="body2">  <span>Design Calculations of vessels</span></Typography>
//                                     </ li>

//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Preparation of mechanical dataset</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Preparation of mechanical design drawings</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Preparation of specifications</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Painting and insulation specifications and MTO</span></Typography>
//                                     </ li>
//                                     < li >
//                                         <Typography style={{ color: '#303030' }} variant="body2"> <span>Package Equipments</span></Typography>
//                                     </ li>
                                    

//                                 </ ul>
//                             </div>
//                             </Element>
//                         </Grid>

//                                             </Grid>


//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Services;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactFontLoader from 'react-font-loader';
import IconBreadcrumbs from './breadcrumb';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import image from '../assets/ServicesBg1.png'


const Services = () => {
    
    const useStyles = makeStyles((theme) => ({
        // bdy:{
        //     marginLeft:'35px',#53bcc0
        // },
    }));


    const classes = useStyles();
    const matches = useMediaQuery('(min-width:700px)');
    return (
        <div style={{paddingBottom:'4vh'}}>
            <ReactFontLoader url='https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap' />
            <IconBreadcrumbs page={'Services'} />
            <div className={classes.container1}>
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        {/* <Grid item xs={6}>
                            <img width='100%' height='100%' src={"http://vaapenergy.com/wp-content/uploads/2020/03/Industrial.jpg"} />
                        </Grid> */}
                        <Grid item style={{ display: 'flex',   alignitems:'flex-start' }} xs={12}>
                            <img src={image} alt='Services' style={{ width: '100%' }}></img>
                        </Grid>
                        <Grid item style={{ display: 'flex',   alignitems:'flex-start' }} xs={matches?4 : 12}>

                            <div className={classes.textbody}>
                                {/* <Typography variant="h5" className={classes.head} >Vaap Energy</Typography> */}

                                <div className={classes.bdy} >
                                    <Typography variant="h6" style={{ color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>CONCEPT- FEED- PRIMARY & DETAILED ENGINEERING SERVICES</Typography>

                                    <ul className={classes.lis} aria-label="contacts">
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Load Schedule</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Single Line Diagrams</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030' }} variant="body2">  <span>Equipment Sizing & Selection</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Adequacy Checks of Equipment and Cables</span></Typography>
                                        </ li>

                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Protection and Metering SLD</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Substation Layout Plan and Sections</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Substation Clearance Diagrams</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Erection Key Diagram</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Earthing & Lightning Protection</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Lighting System Design</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Procurement Support</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Conduit Layouts</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Cable Routing and Schedule</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Drum Schedule</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Control Room Equipment Layout</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>SCMS, PMS and SCADA System</span></Typography>
                                        </ li>
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Project Bill of Materials</span></Typography>
                                        </ li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item style={{ display: 'flex',   alignitems:'flex-start' }} xs={matches?4 : 12}>

                            <div className={classes.textbody}>
                                {/* <Typography variant="h5" className={classes.head} >Vaap Energy</Typography> */}

                                <div className={classes.bdy} >
                                    <Typography variant="h6" style={{ color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>POWER SYSTEM STUDIES</Typography>
                                    <Typography variant="body1" style={{ color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw' }}>Using Latest ETAP Software Package, Power system studies covers the following</Typography>
                                    < ul className={classes.lis} aria-label="contacts">
                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Load Flow studies</span></Typography>
                                        </ li>

                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Short Circuit studies</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Transient Stability</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Relay Co-ordination</span></Typography>
                                        </ li>

                                        < li >
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Dynamic Stability</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Motor starting Analysis</span></Typography>
                                        </ li>
                                        < li >

                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Harmonic Analysis</span></Typography>
                                        </ li>
                                        < li>
                                            <Typography style={{ color: '#303030'  }} variant="body2">  <span>Arc Flash Analysis</span></Typography>
                                        </ li>

                                    </ ul>
                                </div>
                            </div>
                        </Grid>
                        
                        <Grid item style={{ display: 'flex',   alignitems:'flex-start' }} xs={matches?4 : 12}>
                            <div className={classes.bdy} >
                                <Typography variant="h6" style={{ color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh'}}>OWNER'S ENGINEER SERVICES</Typography>

                                < ul className={classes.lis} aria-label="contacts">
                                    < li >
                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Conceptual design and engineering</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Project cost estimate and schedule</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Preparation of Tender for EPC scope of work</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Post bid clarifications</span></Typography>
                                    </ li>

                                    < li >
                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Bid evaluation and recommendations</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Design review</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Review and approval of design documents</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Review and approval of type test reports</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>FAT at manufacturers’ works</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030' }} variant="body2">  <span>Development & review of O&M procedure</span></Typography>
                                    </ li>

                                </ ul>
                            </div>
                        </Grid>



                        <Grid item style={{ display: 'flex',   alignitems:'flex-start' }} xs={matches?4 : 12}>
                            <div className={classes.bdy} >
                                <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>ENERGY AUDITS</Typography>

                                < ul className={classes.lis} style={{marginLeft:'0px'}} aria-label="contacts">
                                    < li >
                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Preliminary Energy Use Analysis</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Walk Through Analysis</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Energy Survey Analysis</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Detailed Analysis</span></Typography>
                                    </ li>

                                    < li >
                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Energy Conservation Measures</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030' }} variant="body2">  <span>Energy Saving & Improvement Opportunities</span></Typography>
                                    </ li>


                                </ ul>
                            </div>

                        </Grid>

                        <Grid item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
                            <div className={classes.bdy} >
                                <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>BUILDING SERVICES</Typography>

                                < ul className={classes.lis} aria-label="contacts">
                                    < li >
                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Lighting calculations</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Lighting layouts</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>DB schedules and single line diagram</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Conduit layouts</span></Typography>
                                    </ li>

                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>Supply, Installation & Commissioning of DG, LV SWBD, MCC, UPS,<br/>Lighting etc.</span></Typography>
                                    </ li>
                                    

                                </ ul>
                            </div>

                        </Grid>
                        <Grid item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
                            <div className={classes.bdy} >
                                <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>Instrumentation & Control Design </Typography>

                                < ul className={classes.lis} aria-label="contacts">
                                    < li >
                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Instrument Schedule</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Specification, Data sheet & Philosophy Documents</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Input/output List</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Hook up Drawings</span></Typography>
                                    </ li>

                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>Loop Drawings</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>Cable block Diagrams</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>Installation Drawings</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>Procurement Support (Including Preparation of MR, PR, Tender Bid Evaluation, Vendor document Review & Attending FAT)</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>Cable Schedule and Interconnection Schedule</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>Cable Routing Layout</span></Typography>
                                    </ li>

                                </ ul>
                            </div>

                        </Grid>

                        <Grid item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
                            <div className={classes.bdy} >
                                <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>SCADA/AUTOMATION </Typography>

                                < ul className={classes.lis} aria-label="contacts">
                                    < li >
                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Input/output  tag lis</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Cause & effect diagram</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>PID loops for control valve & pumps</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Graphics, Faceplates, alarms etc for HMI</span></Typography>
                                    </ li>

                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>PLC panel design</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>SCADA/PLC Programming</span></Typography>
                                    </ li>
                                </ ul>
                            </div>

                        </Grid>

                        <Grid item style={{ display: 'flex',   alignitems:'flex-start'  }} xs={matches?4 : 12}>
                            <div className={classes.bdy} >
                                <Typography variant="h6" style={{color: '#093C73', fontFamily: 'Rubik', marginLeft: '1vw', marginTop: '3vh' }}>STRUCTURAL</Typography>

                                < ul className={classes.lis} aria-label="contacts">
                                    < li >
                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Advance Bill of Materials</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Anchor Bolt Layout & Details</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Erection / GA Drawings</span></Typography>
                                    </ li>
                                    < li >

                                        <Typography style={{ color: '#303030'  }} variant="body2">  <span>Shop Drawings</span></Typography>
                                    </ li>

                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>NC Data</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>Material List & Bolt list Reports</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>BOQ & Bought Out Lists</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>KISS / Fabtrol Reports</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>IFC  files for integration</span></Typography>
                                    </ li>
                                    < li >
                                        <Typography style={{ color: '#303030' }} variant="body2"> <span>3D DWG model</span></Typography>
                                    </ li>
                                    
                                </ ul>
                            </div>

                        </Grid>

                                            </Grid>


                </div>
            </div>
        </div>
    )
}

export default Services;

