import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {deleteBlog,getBlogs} from "../actions/blogs"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));


export default function InteractiveList({arg}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const blogs = useSelector(state => state.blogs)

  const handleOnClick =(blog) =>{


    dispatch(deleteBlog(blog._id));

  }
  useEffect(()=>{

    dispatch(getBlogs())
  

  })
  return (
    <div style={{height:'70vh',overflowY:'scroll'}} className={classes.root}>
        
        <div className={classes.demo}>
            <List>
             {blogs.map((blog,index)=>
                 <ListItem key={index}>
                 <ListItemText
                   primary={blog.title}
                   secondary={blog.summary}
                 />
                 <ListItemSecondaryAction>
                   <IconButton onClick={()=>{handleOnClick(blog)}} edge="end" aria-label="delete">
                     <DeleteIcon />
                   </IconButton>
                 </ListItemSecondaryAction>
               </ListItem>
             )}
                
            </List>
          </div>

    </div>
  );
}
