import * as api from "../api"

export const getNews = () => async(dispatch) => {
    try {
        const { data } = await api.getNews()
        dispatch({ type: 'FETCH_ALL_NEWS', payload: data });
    } catch (error) {
        console.log(error);
    }

}

export const createNews = (news) => async(dispatch) => {
    try {

        const { data } = await api.postNews(news);
        dispatch({ type: 'CREATE_NEWS', payload: data })


    } catch (error) {
        console.log(error)
    }
}
export const deleteNews = (id) => async(dispatch) => {
    try {
        await api.deleteNews(id);
    } catch (error) {
        dispatch({ type: 'DELETE_NEWS', payload: id })
    }
}