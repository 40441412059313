import * as api from "../api"

export const getAdmin = () => async(dispatch) => {
    try {
        const { data } = await api.fetchAdmin()
        dispatch({ type: 'FETCH_ADMIN', payload: data });
    } catch (error) {

    }

}